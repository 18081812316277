import * as React from "react";

const List = (props) => {
  return (
    <ul className="leading-loose">
      {props.items.map((item) => (
        <li key={item.name}>
          <a
            href={item.href}
            className="text-sm text-gray-500 opacity-50 hover:text-gray-900 hover:opacity-75"
          >
            <span className="sr-only">{item.name}</span>
            {item.icon ? (
              <item.icon className="h-6 w-6" aria-hidden="true" />
            ) : (
              item.name
            )}
          </a>
        </li>
      ))}
    </ul>
  );
};

export default List;
