import * as React from "react";
import { Popover, Transition } from "@headlessui/react";
import { MenuIcon, XIcon } from "@heroicons/react/outline";
import { Fragment } from "react";
import { Link } from "gatsby";
import logoWhite from "../images/conexum-white.svg";
import logoColor from "../images/conexum-color.svg";

const navigation = [
  { name: "Who we are", to: "/who-we-are" },
  // { name: "Insight", to: "#" },
  { name: "Investors", to: "/investors" },
  { name: "Careers", to: "/careers" },
  { name: "Contact us", to: "/contact-us" },
];

export default function Navbar() {
  return (
    <nav>
      {/* NAVBAR */}
      <Popover>
        {({ open }) => (
          <>
            <div className="container mx-auto flex items-center">
              <div className="px-3 md:px--0 flex items-center justify-between w-full md:w-auto">
                {/* LOGO */}
                <Link to="/" className="relative">
                  <img
                    className="h-16 lg:h-24 w-auto"
                    src={logoWhite}
                    alt="Conexum logo (black and white)"
                  />
                </Link>
                {/* END LOGO */}
                {/* HAMBURGER MENU */}
                <div className="mr-2  md:hidden">
                  <Popover.Button className="z-40 bg-gray-800 rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:bg-gray-700 focus:outline-none focus:ring-2 focus-ring-inset focus:ring-white">
                    <span className="sr-only">Open navigation menu</span>
                    <MenuIcon className="h-6 w-6" />
                  </Popover.Button>
                </div>
                {/* END HAMBURGER MENU */}
              </div>
              <div className="flex items-center flex-1 justify-end">
                {/* LOGO + HAMBURGER */}

                {/* END LOGO + HAMBURGER */}

                {/* NAV ITEMS */}
                <ul className="hidden space-x-4 lg:space-x-16 md:flex md:ml-10">
                  {navigation.map((navlink) => (
                    <li className="justify-center" key={navlink.name}>
                      <Link
                        to={navlink.to}
                        className="
                        transition duration-500 ease-in-out
                        text-sm
                        font-normal
                        uppercase
                        text-white
                        hover:text-blue-300
                        "
                      >
                        {navlink.name}
                      </Link>
                    </li>
                  ))}
                </ul>
                {/* END NAV ITEMS */}
              </div>
            </div>
            {/*END NAVBAR*/}

            {/* TOGGLED HAMBURGER MENU ITEMS */}
            <Transition
              show={open}
              as={Fragment}
              enter="duration-150 ease-out"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="duration-100 ease-in"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Popover.Panel
                focus
                static
                className="z-40 absolute top-1 inset-x-0 p-2 transition transform origin-top-right md:hidden"
              >
                <div className="rounded-lg shadow-md bg-white ring-1 ring-black ring-opacity-5 overflow-hidden">
                  <div className="px-5 pt-4 flex items-center justify-between">
                    <div>
                      <img className="h-8 w-auto" src={logoColor} alt="" />
                    </div>
                    <div className="-mr-2">
                      <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                        <span className="sr-only">Close menu</span>
                        <XIcon className="h-6 w-6" aria-hidden="true" />
                      </Popover.Button>
                    </div>
                  </div>
                  <ul className="px-2 pt-2 pb-3 space-y-1">
                    {navigation.map((item) => (
                      <li key={item.name}>
                        <a
                          href={item.to}
                          className="block uppercase px-3 py-4 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50"
                        >
                          {item.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </Popover.Panel>
            </Transition>
            {/* TOGGLED HAMBURGER MENU ITEMS */}
          </>
        )}
      </Popover>
      {/*END NAVBAR*/}
    </nav>
  );
}

/*
s
*/
